.resend_otp {
    margin-top: .5rem;
    text-align: right;
    letter-spacing: 0.19px;
    color: #3D7CC9;
    opacity: 1;
    font-size: 14px;
    /* cursor: pointer; */
}

@media  (max-width:425px){

    .formInput>div>div>input{
    width: 40px!important;
    height: 40px!important;
    }
    .formInput>div>div>span{
        margin:0px 10px!important
    }
}