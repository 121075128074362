.summaryModalLabel{
    width: 50% !important;
}
.summaryModalLoader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}
.attachmentDetailsText{
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #3476CD !important;
}
.attachmentDetailsContainer{
    margin-top: 10px;
}