
.headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}
.modal-body-container{
    margin: 12px 0;
    overflow: auto;
    padding-right: 10px;
    max-height: calc(100vh - 350px);
}
.footer-container{
    margin-top: 12px;
    display: flex;
    justify-content:center;
    align-items: center;
    gap:20px;
}
.centralModalTitle{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #3476CD !important;
}
.modalDescription{
    font-size: 12px;
    font-weight: 400;
    color: #7D7D7D;
    line-height: 18px;
}
.modalCrossIcon{
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
}
.defaultModalButton{
    color: #3476CD;
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}