.chat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: "Poppins", "sams-serif";
  height: 100%;
  max-height: calc(100% - 0px);
  /* overflow-y: auto; */
  scroll-behavior: smooth;
  position: relative;
}

.chat__header {
  z-index: 10;
  display: -ms-flex;
  display: flex;
  /* margin-bottom: 0.5rem; */
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #707070;
  border-top: none;
  background-color: #3D7CC9;
  padding:0 10px
  /* border-bottom-right-radius: 15px; */
}

.chat__headerInfo {
  max-width: calc(100% - 100px);
}
.dotted-line{
  position: absolute;
}
.chat__headerInfo>h3 {
  margin-bottom: 3px;
  font-weight: 500;
  font-size: 16px;
  margin-top: 5px;
  word-break: break-word;
  color: #ffffff;
  max-width: 242px;
}

.chat_body_main {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  /* padding: 2rem 0; */
  /* scroll-behavior: smooth; */
  /* height:85% */
  /* height:"85%" */
  /* flex-grow: 1; */
}

.chat__body {
  /* -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; */
  /* background-image: url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-enl_MP6FbOX05KwlCxWWfR8guLdylyf20Q&usqp=CAU"); */
  background-repeat: repeat;
  background-position: center;
  padding: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  gap: 1rem;
}

.chat__body .avatar__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat__body .avatar__container .chat__timestamp {
  margin-left: 10px;
  font-size: 10px;
}

.chat__body .chat__author_name_time_container {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template: 1fr / 1fr 1fr;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  gap: 1rem;
}

.chat__body .chat__author_name_time_container .chat__author_time {
  margin: 0;
  font-size: 70%;
}

.chat__body .chat__author_name_time_container .chat__author_name {
  margin: 0 !important;
  margin-top: 0.5rem;
  text-align: end;
  font-size: 12px;
  font-weight: bold;
}

.chat__body .chat__author_name_time_container .chat__author_name span {
  font-size: 90%;
}

.chat__body .tick_icon_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0.5rem auto -0.5rem auto;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

.chat__SideChange {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.chat__message {
  position: relative;
  font-size: 14px;
  line-height: 18px;
  padding: 10px 10px 0rem 10px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  background-color: #F5F5F5;
  color: '#00263E';
  word-break: break-word;
}

.chat__reciever {
  margin-left: auto;
  background-color: #F4F8FD;
  color: black;
}

.chat__text {
  font-weight: 600;
  font-size: 14px;
}

.private-chat-popup-info{
  font-size: 14px;
}

/* .private-chat-popup-info>p{
  margin: 0;
} */

.highlight-private-icon{
  position: fixed;
  background: #ffffffb5;
  border-radius: 50%;
}

.privateIconPath{
  position: fixed;
  top: 19%;
  width: 52%;
  height: 16%;
  right: 0;
}

.chat__footer {
  bottom: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* height: 62px; */
  background-color: #00263e;
}

.chat__footer__private{
  background: #F5F5F5;
}

.chat__footer>div>form {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.chat__footer>form>div>textarea {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: inherit;
  color: #ffffff;
}

.chat__footer ::-webkit-textarea-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}

.chat__footer :-ms-textarea-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}

.chat__footer ::-ms-textarea-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}

.chat__footer ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffffff;
  opacity: 1;
  /* Firefox */
}

.chat__footer>div>form>button {
  display: none;
}

.chat__headerRight {
  width: unset !important;
}

.chat__headerRight>.MuiSvgIcon-root {
  /* margin: 10px; */
  flex-basis: 20%;

  color: #ffffff;
}

.chat__footer>div>.MuiSvgIcon-root {
  /* margin: 10px; */
  /* color: #ffffff; */
}

.chat__footer>div>.MuiPopover-root .MuiPopover-paper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.private_chat{
  background: #666A70;
  animation: switch__chat 5s linear infinite;
}

@keyframes switch__chat {
    from { transform: rotateY('0deg'); }
    to {transform: rotateY('360deg');}
}

.private_message_recieved{
  background-color: #818181;
  color: #EAF1F9;
}

.private_message_sender{  
  background-color: #90959D;
  color: #EAF1F9;
}

.popOver {
  margin-bottom: 10px;
  /* box-shadow: none !important;
  background-color: transparent !important; */
}

.popOver .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  /* background-color: transparent !important; */
  /* -webkit-box-shadow: none !important; */
  /* box-shadow: none !important; */
  /* flex-wrap: wrap; */
}

/* css for the arrow on hover */
.arrow {
  border: solid rgb(145, 145, 145);
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrowContainer {
  /* margin-left: 5px; */
  color: rgb(145, 145, 145);
  /* height: 21px;
  width: 10px; */
  margin-right:-8px;
  position: absolute;
  right: 3px;
  margin-top: -7px;
  cursor: pointer;
}

.arrowContainer:hover {
  opacity: 1;
}

/* pop on right click of the message */

.options-btn__options {
  z-index: 1000;
  top: 25px;
  right: 0;
  position: absolute;
  width: 200px;
  padding: 10px 0;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 #00000042, 0 2px 10px 0 rgb(0 0 0 / 16%);
  transition: all 0.2s ease-in;
  opacity: 0;
  transform: scale(1);
  display: none;
}

.options-btn__options--active {
  opacity: 1;
  transform: scale(1);
  display: block;
}

ul {
  list-style: none;
}

.options-btn__option {
  padding: 15px 20px;
  color: #000000;
  font-size: 0.9rem;
  cursor: pointer;
}

/* templates css */

.slick-slider {
  width: 90% !important;
}

.activeImage {
  border: 1px solid black;
}


.slick-slide {
  white-space: nowrap;
}

.slick-slider {
  margin: auto;
}

.slick-next:before,
.slick-prev:before {
  color: black !important;
}

/* chat body */
.messageContainer {
  display: flex;
  position: relative;
}

.messageBody {
  font-weight: 400;
  width: 98%;
}

.messageFooter {
  margin-top: 5px;
  margin-bottom: 2px;
  display: flex;
  justify-content: flex-end;
}

.timeStamp {
  display: flex;
  font-size: 12px;
}

.time {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 15px;
  color: #3D7CC9;
}

.private_time{
  color: #FFFFFF;
}

.tick {
  display: flex;
  align-items: center;
}

/* reply text */
.replyBody {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background: white;
  border-bottom: 3px solid orange;
  border-radius: 10px;
  color: black;
  margin-bottom: 10px;
  cursor: pointer;
}

.replyBody:hover {
  box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75) inset;
  /* filter: drop-shadow(1px 1px 1px rgb(170, 170, 170)); */
}


.replyContainer {
  flex: 9;
}

.replyMedia {
  margin-left: 5px;
}

.replyText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.activeDoc {
  border: 2px solid #00bfa5;
  margin: 5px;
  border-radius: 10px;
}

.messageCreator {
  color: #7a7a7a;
  font-size: 12px;
}

.privateMessageCreator{
  color: white;
}

.chatTemplate {
  margin-bottom: 20px;
}

.msgMedia {
  display: flex;
  flex-direction: column;
  padding: 6px 10px 4px;
  background: white;
  border-radius: 8px;
  color: black;
  margin-bottom: 10px;
}

@media(min-width: 900px) {
  .msgMedia {
    padding: 16px 10px 14px;
  }
}

.downloadMedia:hover {
  cursor: pointer;
}

.mediaContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  background: white;
  padding: 0.8rem;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.mediaContainer:hover {}

.mediaContainer>img {

  width: 100%;
  height: 100%;
  max-height:100px;
  object-fit: contain;
}

.numDocsContainer {
  background: #7a7a7a;
  height: 44px;
  width: 44px;
  opacity: 0.9;
  margin-top: -1.5rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  top: 50%;
  /* transform: translateY(-50%); */
}

.insPopUp {
  width: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.insBody {
  display: flex;
  flex-direction: column;
  width: 250px;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  /* height: 80vh; */
}

.uploadHeading {
  background: blue;
  color: white;
  font-size: 25px;
  padding: 10px;
  position: relative;
}

.uploadBody {
  padding: 20px 0;
  padding-top: 0;
  max-height: 70vh;
  display: grid;
  grid-template: 1fr/ 1fr 2fr;
}

.uploadPreview {
  /* flex: 3;
  display: flex;
  flex-direction: column;
  min-width: 25rem; */
  padding: 10px;
  padding-top: 20px;
}

.uploadNewDoc {
  /* width: fit-content; */
  margin-top: 10px;
  padding: 10px;
}

.docPreview {
  display: flex !important;
  flex-direction: column !important;
  border: 1px solid #c8c8c8 !important;
  height: 55vh !important;
  justify-content: center !important;
  align-items: center !important;
}

.uploadRightContainer {
  /* flex: 4; */
  position: relative;
  overflow: auto;
  margin: 20px;
  margin-top: 0;
  padding-top: 20px;
}

.uploadedThumbnails {
  display: grid;
  padding: 0 1rem;
  grid-template: repeat(auto-fit, minmax(10rem, 1fr)) / repeat(3, 1fr);
  gap: clamp(1rem, 3vw, 2rem);
  /* grid-auto-flow: column; */


  /* display: flex;
  flex-wrap: wrap;
  gap: 2rem; */

}

.css-cyxlny-MuiPaper-root-MuiDialog-paper {
  /* width:90vw!important;
  max-width: 90vw!important;
  transform: translate(5%,0px); */
}

.docThumbnail {
  border: 1px solid #c8c8c8;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  width: 15vw;
  max-height: 8rem;
  height: 100%;
}

.sendUploadedDocs {
  background: blue;
  padding: 5px;
  border-radius: 18px;
  margin-bottom: 10px;
  position: absolute;
  /* margin: 0px; */
  bottom: 0px;
  right: 0px;
  margin-right: 20px;
}

.sendUploadedDocs>Button {
  color: white !important;
}

.docTitle {
  width: 15vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: grey;
  font-size: 13px;
}

.completeDocThumbnail {
  /* margin-top: 15px; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5rem;
}

.retryContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  background: white;
  border-radius: 8px;
  /* position: relative; */
  cursor: pointer;
  color: #3D7CC9;
  position: absolute;
  z-index: 10;
  font-size: 14px;
  width: 88%;
  align-items: center;
}

.smoothScroll {
  scroll-behavior: smooth;
}

.botMessage {
  min-width: unset !important;
}

#simple-popover>.MuiPopover-paper {
  box-shadow: none;
  /* background-color: transparent !important; */
}

@keyframes highlight {
  0% {
    background: whitesmoke;
  }

  100% {
    background: none;
  }
}

.highlight {
  animation: highlight 2s;
}


.printContainer {
  position: absolute;
  bottom: 0px
}

.printBox {
  background: #F5F5F5;
  border: 1px solid #3D7CC9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-size: 17px;
  padding: 10px;
  position: relative;
}

.grab-attention-element {
  position: relative;
}

.grab-attention-element::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: 1;
  background: #E8F4FD;
  mix-blend-mode: multiply;
  border-radius: inherit;
  animation: opacity-pulse 3s ease infinite;
  opacity: 0;
}

@keyframes opacity-pulse {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.inputTextArea {
  height: 50px;
  resize: none;
  /* overflow: hidden; */
  word-wrap: break-word;
  white-space: nowrap;
  line-height: 17px;
}

textarea::-webkit-scrollbar {
  width: 1em;
}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  visibility: hidden;
}

textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  visibility: hidden;
  outline: 1px solid slategrey;
}


.inputText {
  color: white;
  width: 100%;
  min-height: 20px;
  max-height: 88px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 15px;
  font-weight: 400;
  word-wrap: break-word;
  white-space: pre-wrap;
  outline: none;
}

.placeholderMessage {
  width: 100%;
  color: #ffffff70;
  position: absolute;
  margin-top: -2px;
  font-family: 'Roboto';

}

.hideElement {
  display: none !important;
}

.inputText::-webkit-scrollbar {
  width: 20px;
}

.inputText::-webkit-scrollbar-track {

  background-color: #072a44;
}

.inputText::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.inputText::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.endOfChat {
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 20px;
  align-items: baseline;
}
.scrollableDiv{
  /* overflow:unset!important; */
  scroll-behavior: auto;
  /* overflow-y:scroll!important */
}

.unreadMessage{
  background: #0000001A;
  position: absolute;
  padding: 5px 20px;
  border-radius: 8px;
  font-size: 14px;
  color: #00263E;
  left: 50%;
  bottom: 1%;
  transform: translate(-50%, -1%);
  width: max-content;
  visibility: hidden;
  animation:showUnreadToaster 5s ease-in 0s;
}

.pxRoleChatMessages{
  text-align: center;
  font-size: 14px;
  margin: 0;
}

.checkboxOption{
  font-size: 16px;
  cursor: pointer;
  color: #4c4b4b;
  padding-left: 10px;
}

.checkboxOption>span{
  font-size: 20px;
}

@keyframes showUnreadToaster {

  to{
    visibility: visible;
  }
  from{
    visibility: hidden;
}
}
@media (max-width:520px) {
  .privateIconPath{
    width: 50%;
    top: 18%;
    right: 8%;
  }
}