.required:after {
  content: " *";
  color: #e32;
}

.messageTable>th {
  white-space: nowrap;
  background: #FFBE00;
  color: white;
  font-weight: 600;
}

.messageTableBody>tr {
 word-break: normal;
}

.my-mobile-date-time-picker {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
}
.my-mobile-date-time-picker-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.my-mobile-date-time-picker-input input {
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  outline: none;
}
.my-mobile-date-time-picker-icon {
  font-size: 20px !important;
  color: #666;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  bottom: 15px;
}