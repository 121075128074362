.selectFieldLabel{
    margin: unset !important;
    color: #393939 !important;
    font-size: 10px !important;
    font-weight: 500 !important;
}
.selectFieldError{
    margin: unset !important;
    font-size: 10px !important;
    font-weight: 500 !important;
}
.selectMenuItemsStyle{
    font-size: 12px !important;
    font-weight: 500 !important;
    min-height: 28px !important;   
}