.list {
  color: #3D7CC9;
  font-size: 20px;
  font-family: "Poppins", sans-serif !important;
  font-weight: medium;
  text-align: left;
}

.data_list {
  color: #64696f !important;
}

.iconButton {
  padding-top: 11px;
  padding-bottom: 11px;
}

.textArea {
  color: #64696f !important;
}

.textArea > div > input {
  padding: 1px 14px !important;
  border-radius: 4px;
}

.submitButton:hover {
  background-color: #3D7CC9 !important;
}

.timer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 50px;
}

.timer > input {
  padding: 8px 10px;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.timerInput > div > div input {
  padding: 8px 10px;
}

.textField {
  border-color: "gray";
  margin-top: 20px;
}

.textField > div > div textarea {
  overflow-y: hidden !important;
}

.css-8xja7g-MuiDialogContent-root {
  padding: 4px 24px !important;
}

.bgColor {
  background-color: white;
}

.userColor {
  background-color: #3D7CC9;
}

.radioButton > label > span {
  font-size: 16px;
}

.patient-info-item {
  margin: 1rem 0 !important;
}


.acceptPopUpfields{
  width:fit-content;
  flex-basis:auto!important;
  max-width:none!important
}