.checklistTable {
    width: 100%;
    margin-top: -15px;
    font-size: calc(8px + (20 - 12) * ((100vw - 360px) / (1920 - 360))) !important;
}

table {
    border-collapse: separate;
    border-spacing: 2.4vw 1rem;
}

th {
   
}
thead{
    z-index:99
}

td {
    text-align: center;
    vertical-align: middle;
    background: white;
    border-radius: 5px;
    height: 50px;
    
}

.tdExtraPadding {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    height:100%;
    align-items: center;
    gap:1px;
    position:relative
}

.tdExtraPadding>div>span {
    margin-left: 10px;
}

/* .infinite-scroll-component {
    overflow: unset!important;
} */
.popoverTable {
    background: white !important;
    font-size: calc(7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))) !important
}

.noDataFound {
    color: #666A70;
    font-size: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.popoverTable {
    display: flex;
    flex-direction: column;
    box-shadow: "5px 5px 15px #0000000D";
    font-size: 14px;
}

.popoverRow {
    display: flex;
    justify-content: space-between;
}

.popoverData {
    flex: 1;
    padding: 10px;
}

.boxPointer {
    border: solid 10px transparent;
    border-bottom-color: #fff;
    position: absolute;
    background:black
}

.infoIconLine{
    display:flex;
    justify-content:center;
    align-items:center;
    border-left:2px solid #F5F5F5 ;
    padding-left:10px;
    height:100%
}