
.group-info-body-container .modal-header {
  background-color: #3D7CC9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.group-info-body-container .modal-header .search-input-container {
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.25em;
  font-size: 16px;
}

.group-info-body-container .modal-header .search-input-container input {
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font-size: inherit;
}

.group-info-body-container .modal-header .close-modal {
  position: absolute;
  top: 0;
  border-radius: 100%;
  background: #fff;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}

.teams-container {
  background-color: #fff;
  /* padding: 1rem clamp(1.5rem, 5vw, 3rem); */
  overflow-y: auto;
  max-height: 76vh;
  height: 80vh;
}

.teams-container .team-item {
  max-height: 40vh;
  overflow-y: auto;
}

.teams-container .team-item .team-title-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.teams-container .team-item .team-title-container .team-title {
  text-align: center;
  background: #ebebeb;
  border-radius: 20px;
  color: #64696f;
  font-size: 14px;
  padding: 0.5rem;
  text-transform: capitalize;
  margin: 0;
  margin-bottom: 1rem;
}

.teams-container .team-item .team-members-container {
  overflow-x: hidden;
}

.teams-container .team-item .team-members-container .team-member-item {
  border-bottom: 1px solid #ccc;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: auto 1fr;
      grid-template: 1fr / auto 1fr;
  height: 100%;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
}

.teams-container .team-item .team-members-container .team-member-item .content-container p {
  margin: 0;
}

.teams-container .team-item .team-members-container .team-member-item .content-container .person {
  max-width: 100%;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.teams-container .team-item .team-members-container .team-member-item .content-container .person.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  max-width: 11ch;
}

.teams-container .team-item .team-members-container .team-member-item .content-container .phone {
  font-size: 12px;
  color: #3D7CC9;
  text-decoration: none;
}

.teams-container .team-item .team-members-container .has-addRemove-auth {
  -ms-grid-rows: 1fr;
  -ms-grid-columns: auto 1fr auto;
      grid-template: 1fr / auto 1fr auto;
}

.group-info-body-container .person-add-icon-container {
  position: -webkit-sticky;
  position: sticky;
  bottom: 3rem;
  right: 3rem;
  margin-right: 3rem;
  z-index: 99;
  height: 0;
  -webkit-transform: translate(0, -4rem);
          transform: translate(0, -4rem);
}

.group-info-body-container .person-add-icon-container .btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
