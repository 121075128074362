.patientJourney_timeline li:before{
  content: unset !important;
}
.patientJourney_timelineContent{
  display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.patientJourney_timelineContent p{
  margin: 0px;
}
.patientJourney_eventDetails{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.patientJourney_eventName{
  font-size: 14px;
  color: #393939;
}
.patientJourney_eventDetails p{
  font-size: 12px;
}
.patientJourney_timeline{
  padding: 0 2rem !important; 
}
.noDataAvailable{
  display: flex;
  height: 80%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.noDataAvailable p {
  margin: 0;
}