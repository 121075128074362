.parentCardContainer {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 6px 0px #75757533;
  background-color: #fff;
  width: 90%;
  align-items: baseline;
  border-radius: 10px;
  padding: 10px;
  gap: 5px;
  position: relative;
}

.rightSideOfCard {
  display: flex;
  width: 85%;
}

.rightSideOfCard table {
  width: 100%;
  border-spacing: 3px;
}

.rightSideOfCard_hospitalName{
  text-align: left;
  font-size: 12px;
  font-weight: 500;
}

.rightSideOfCard table td {
  height: auto;
  width: 45%;
  font-size: 10px;
  text-align: left;
  font-weight: 500;
  font-family: 'Poppins';
}

.rightSideOfCard_activeIpdCount {
  color: #000000;
}

.rightSideOfCard_pendingFieldsCount {
  color: #E90000;
}

.todayBox {
  text-align: center;
  background-color: rgba(52, 118, 205, 0.15);
  width: 45px;
  height: 20px;
  padding: 3px;
  border-radius: 2px;
}
.pastBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}
.pastBox .MuiSvgIcon-root{
  height: 15px;
  width: 15px;
}

.central_forwardIcon{
  height: 15px;
  position: absolute;
  right: 0.5rem;
  top: 40%;
}

.rightSideOfCard_totalIpdCount{
  color: #3476CD !important;
}