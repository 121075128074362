.table-heading{
    color: #3D7CC9;
    margin-block: 0;
}
.table{
    display: flex;
    width: 100%;
}

.table .table-cell{
    border: 1px solid #F5F5F5;
    width: 23%;
    padding: 10px 5px;
}

.table .table-head{
  color:#00263E
}

.table .table-body{
    font-size: 14px;
    color: #3D7CC9;
}

.table .statusButton{
    display: flex;
    width: 31%;
    gap: 10px;
}

.active-sanity-status{
    background-color: grey !important;
    color: white !important;
    opacity: 50%;
}

@media screen and (max-width:900px) {
    .table-container{
        width: 100%;
        overflow: auto;
    }
    .table-container>.table{
        min-width: 100vw;
    }

    .table .table-cell{
        min-width: 50vw;
    }
}