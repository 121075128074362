#upload-file-dischargeUploadFile {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 82%;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    /* display: none; */
}
#upload-file-admissionUploadFile {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 82%;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    /* display: none; */
}
#upload-file-others {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 82%;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    /* display: none; */
}
#upload-file-uploadImages {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 82%;
    height: 40px;
    border-radius: 20px;
    cursor: pointer;
    /* display: none; */
}
.fileUploadInputButton {
    color:#fff;
    font-weight: 500;
    padding: 4px 10px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
    width: 110px;
    gap: 5px;
    font-size: 12px;
    border: 0.5px solid #3476CD;
    background: #3476CD;
    align-items: center;
    cursor: pointer;

}
.fileUploadHeading{
    font-size: 12px;
    font-weight: 500;
    color: #393939;
}
.fileUploadMainContainer{
    margin-top: 16px;
    width: 100%;
}
.fileUploadContainer{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}
.filePreviewContainer{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 10px;
    justify-content: space-between;
}
.filePreviewChildContainer{
    display: flex;
    align-items: center;
    gap: 2px;
    width: 58%;
}
.showFileName{
    border: none;
    font-size: 10px;
    font-weight: 500;
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #3476CD;
    text-decoration: underline;
}
.filePreviewImagesContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;
    width: 78%;
}
.filePreviewProofText{
    font-size: 12px;
    font-weight: 500;
    color: #7D7D7D;
    white-space: nowrap;
    width:15px;
}
.filePreviewNameContainer{
    display: flex;
    align-items: center;
    gap: 5px;
    width: 90%;
}
.filePreviewName{
    font-size: 10px;
    font-weight: 500;
    color: #393939;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #3476CD;
    text-decoration: underline;
}
.fileUploadLoader{
    display: flex;
    justify-content: center;
}
.filePreviewAmountContainer{
    display: flex;
    align-items: center;
    gap: 5px;
}
.totalCollectionAmountContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    justify-content: space-between;
    width: 100%;
}
.totalCollectionText{
    font-size: 12px;
    font-weight: 500;
    color: #393939;
}
.totalCollectionAmount{
    font-size: 12px;
    font-weight: 400;
    color: #393939;
    border: 1px solid #393939;
    width: 100px;
    padding: 6px 10px;
    border-radius: 4px;
}
.filePreviewUrlContainer{
    justify-content: flex-start;
    margin-top: 10px;
    height: 30px;
    padding: 0 5px;
}
.onlyAmountText{
    font-size: 12px;
    font-weight: 500;
    color: #393939;
    width: 64px;
}
.fileRemovedText{
    width: 50px;
    border: unset;
    color: #EA4336;
    font-size: 10px;
    font-weight: 600;
    margin: 8.5px 0;
    background: unset;
}
.crossButtonContainer{
    display: flex;
    justify-content: end;
}
.deletedBackground{
    background: #FFEFEE;
    padding: 0 5px;
}
.filePreviewDataContainer{
    padding: 0 5px;
}
.highLightAmount{
    color: #0CA625;
}
.modalBodyDataContainer{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
}
.uploadFileDivider{
    width: 100%;
    height: 1px;
    margin: 15px 0;
    background-color: #d7d3d3;
}