.centralCommonHeader_container{
  color: #FFFFFF;
  background-color: #3476CD;
  height: 50px;
  padding: 5px 20px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  gap: 4px;
}
.centralCommonHeader_container h3{
  margin: 0px;
  font-size: 16px;
}
.hospitalNameHeading{
  display: flex;
  align-items: center;
  width: 90%;
}
.hospitalNameHeadingText{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 83%;
}
.centralCommonHeader_absolute{
  /* position:absolute;
  left:1rem; */
  width: unset !important;
}
.centralCommonHeader_header{
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:0.5rem;
  width:82%;
}
.itemCount{
  margin-left: 3px;
}
.centralCommonHeader_container .ForwardRef-root-1{
  height: 35px;
  border-radius: 50px;
  flex: 1;
}
.central_clearIcon{
  height: 10px;
  width: 10px;
  margin-right: 15px;
}
.centralCommonHeader_container .search-input-container input::placeholder{
  color: #fff;
}
.centralCommonHeader_patientAvatar{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.centralCommonHeader_datePicker_container{
  padding: 15px 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.centralCommonHeader_datePicker_header{
  margin: 0;
  color: #393939;
  font-size: 12px;
  font-weight: 500;
}
.centralCommonHeader_datePicker{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  width: 100%;
  margin-top: 1px;
}
/* 
.centralCommonHeader_datePicker .MuiFormLabel-root{
  transform: translate(14px, 5px) scale(1)
} */
.phoneCallToast{
  background-color:black !important;
  color: #fff !important ;
}
.my-mobile-date-time-picker {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
}
.my-mobile-date-time-picker-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.my-mobile-date-time-picker-input input {
  flex-grow: 1;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 24px;
  color: #333;
  outline: none;
}
.centralCommonHeader_datePicker .my-mobile-date-time-picker-icon {
  font-size: 20px !important;
  color: #666;
  cursor: pointer;
  position: relative;
  right: unset;
  top: 3px;
}