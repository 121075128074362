.messageInputContainer {
  display: inline-grid;
  width: 100%;
  padding: 10px 0;
}

.editor {
  box-sizing: border-box;
  cursor: text;
  border-radius: 8px;
  background: #f5f5f5;
  max-height: 82px;
  overflow-y: auto;
  color: black;
}

.privateEditor{
  background: #666A70;
}

.privateChatPlaceholder{
  color: white;
}

.privateChatMessageInfo{
  font-size: 12px;
  color: #ffff;
  margin: 0;
  align-self: flex-end;
}

@media(max-Width:768px) {
  .editor::-webkit-scrollbar {
    width: 0px !important;
  }
}

.editor::-webkit-scrollbar {
  width: 20px;
}

.editor::-webkit-scrollbar-track {

  background-color: #072a44;
}

.editor::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.editor::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.DraftEditor-root {
  /* display: flex; */
  align-items: center;
  padding: 0.5em 1.3em;
}

.public-DraftEditorPlaceholder-root {
  position: absolute !important;
  pointer-events: none;
  color: grey !important;
  display: flex;
  justify-content: space-between;
}