.patientDetailsMainContainer{
    margin: 10px 0 40px;
}
.textReadOnlyRow{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 6px;
    gap: 10px;
    width: 100%;
}
.textReadOnlyLabel{
    font-size: 12px;
    font-weight: 500;
    color: #7D7D7D;
    /* width: 62%; */
}
.okSurgeryText{
    /* width: 40%; */
}
.textReadOnlyValue{
    font-size: 12px;
    font-weight: 500;
    color: #393939;
    text-transform: capitalize;
    text-align: right;
}
.patientDetailsLoaderContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.okSurgeryValueContainer{
    display: flex;
    align-items: center;
    background-color: #009817;
    padding: 2px 4px;
    border-radius: 3px;
}
.textReadOnlySurgeryValue{
    font-size: 10px;
    font-weight: 600;
    color: white;
    margin-left: 2.5px;
}

.patientDetails_header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 16px;
}
.patientDetails_statusCard{
    border-radius: 4px;
    padding: 2px 7px;
    font-size: 10px;
    color: #000;
}

.patientDetails_header .MuiButtonBase-root{
    background-color: #FFD54D;
    border-radius: 24px;
    padding: 5px 8px;
    color: #393939;
    font-size: 12px;
}
.feedbackContainer{
    margin: 10px 16px;
    background: white;
    padding: 10px 16px;
    border-radius: 4px;
    box-shadow: 0px 4px 10px 0px #75757533 !important;
}
.feedbackReceivedStatus{
    color:white;
    background-color: #009817;
    padding: 2px 4px;
    border-radius: 3px;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
}