.patientCardMainContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
}

.patientCardParentContainer {
    box-shadow: 0px 4px 10px 0 rgba(117, 117, 117, 0.2);
    background-color: white;
    max-width: 350px;
    border-radius: 10px;
    width: 100%;
}

.patientCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(52, 118, 205, 0.2);
}

.patientCardHeaderLeftContainer {
    display: flex;
    align-items: center;
}

.patientCardHeaderAvatar {
    height: 20px !important;
    width: 20px !important;
    background-color: #3476CD !important;
}

.patientCardHeaderName {
    white-space: nowrap;
    margin-left: 5px;
    color: #3476CD;
    font-size: 14px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 165px;
}

.patientCardHeaderPendingField {
    border-radius: 4px;
    background-color: #DB3A3A;
    padding: 3px 5px 3px 5px;
    font-weight: 600;
    font-size: 10px;
    color: white;
    margin-left: 20px;
    white-space: nowrap;
}

.patientCardBody {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
}

.paymentValueStyle {
    color: #1E9537 !important;
    font-weight: 600 !important;
}

.noResultFound {
    color: #7D7D7D;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    top: 50%;
    width: 100%;
}

.textReadOnlyRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    gap: 5px;
}

.textReadOnlyLabel {
    font-size: 12px;
    font-weight: 500;
    color: #7D7D7D;
}

.textReadOnlyValue {
    font-size: 12px;
    font-weight: 500;
    color: #393939;
    text-transform: capitalize;
    text-align: right;
}

.patientCardHeaderLeftOuterContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.patientCardHeaderJourneyField {
    padding: 2px 10px 2px 10px;
    border-radius: 4px;
    font-size: 10px;
    margin:10px 0 0 0 ;
    color: #000;
}