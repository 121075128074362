.equipmentContentContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 5px;
}
.equipmentCountText{
    font-size: 14px;
    line-height: 21px;
    color: #3476CD;
    font-weight: 500;
}
.equipmentCountContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.equipmentContentContainer1{
    margin-bottom: 20px;
}
.emptyEquipmentData{
    padding: 10px;
    display: flex;
    justify-content: center;
    margin: 20px;
    font-weight: 500;
    color: gray;
    border: 1px dashed;
}