.contentEditableContainer {
    padding: 10px 0;
    position: relative;
}

.editableDiv {
    box-sizing: border-box;
    cursor: text;
    border-radius: 8px;
    background: #f5f5f5;
    height: 45px;
    overflow-y: scroll;
    color: black;
    white-space: pre-wrap;
    overflow-y: auto;
    word-break: break-word;
    padding: 0.5em 2.3em 0.5rem 1.3rem;
    border: none;
    outline: none;
    min-height:40px
}

.privateEditableDiv{
    background: #666A70;
    color: white;
}

.mentionDropDownMobile {
    border-top: 1px solid #eee;
    background: #fff;
    border-radius: 10px;
    position: absolute;
    cursor: pointer;
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    transform-origin: 50% 0%;
    /* margin: -16px; */
    position: absolute;
    top: -160px !important;
    height: 160px;
    overflow-y: scroll;
    background: #f1f1f1;
    width: 100%;
    /* left: 12px !important; */
    color: #00263e;
    transform-origin: unset !important;
    transition: none !important;
}

.mentionItem {
    padding: 7px 10px 10px 10px;
}

.mentionItem:hover {
    background-color: #f8be32;
}

.highlightedText {
 color: #4a85bb;
}

.private__highlightedText{
    color: #9ADCFF;
}

[contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: gray;
    background-color: transparent;
    /* font-size: 10px; */
}

.privateEditableDiv::before{
  color: white !important;
}

.recordButton {
    /* position: absolute; */
    /* border-radius: 50%; */
    border: none !important;
    background-color: transparent;
    height: 35px;
    width: 36px;
    /* transform: translate(0%, -50%);
    right: 0.5em;
    top: 50%; */
    border: 1px solid #CFCFCF;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contentEditableContainer .react-draggable {
    width: 36px;
    height: 35px;
    position: absolute;
    right: 0;
    top: 18px;
    right: 5px;
    display: flex;
    z-index: 2;
}

.currentlyRecording {
    background-color: #FCF5E5;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    z-index: 1;
    animation: ripple 2s linear infinite;
}
/* @keyframes ripple {
    0% {
      box-shadow: 0 0 0 .2rem rgba(155, 84, 84, 0.5),
                  0 0 0 .5rem rgba(219, 108, 108, 0.5),
                  0 0 0 1rem rgba(243, 98, 98, 0.5);
    }
    100% {
      box-shadow: 0 0 0 1rem rgba(212, 77, 77, 0.5),
                  0 0 0 1.2rem rgba(236, 73, 73, 0.5),
                  0 0 0 1.4rem rgba(241, 56, 56, 0.24);
    }
} */

@keyframes ripple {
    0% {
        box-shadow: 0 0 0 .2rem rgb(236 115 115 / 0.1), 0 0 0 .5rem rgb(239 108 108 / 0.1);
    }

    25% {
        box-shadow: 0 0 0 1rem rgba(236, 115, 115, 0.2), 0 0 0 1.2rem rgb(236 73 73 / 0.2);
    }

    50% {
        box-shadow: 0 0 0 .2rem rgb(236 115 115 / 0.1), 0 0 0 .5rem rgb(239 108 108 / 0.1);
    }

    75% {
        box-shadow: 0 0 0 1rem rgba(236, 115, 115, 0.2), 0 0 0 1.2rem rgb(236 73 73 / 0.2);
    }

    100% {
        box-shadow: 0 0 0 .2rem rgb(236 115 115 / 0.1), 0 0 0 .5rem rgb(239 108 108 / 0.1);
    }
}

.cancelRecordingNoDisp {
    display: none;
}

.cancelRecording {
    position: absolute;
    /* border-radius: 50%; */
    border: none !important;
    background-color: transparent;
    height: 40px;
    z-index: 2;
    width: 41px;
    /* transform: translate(0%, -50%); */
    left: 0em;
    top: 50%;
    border: 1px solid #CFCFCF;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translate(0%, -50%);
}

#recordingSpan {
    position: absolute;
    right: 3em;
    transform: translateY(-50%);
    top: 50%;
    font-size: 14px;
}

#duration {
    position: absolute;
    left: 40px;
    color: #3D7CC9;
    font-weight: 600;
    transform: translateY(-50%);
    top: 50%;
    font-size: 14px;
}

.popupDiv {
    margin: 15px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 7px;
}

.popupDiv .popupTextField .MuiOutlinedInput-input, .popupDiv .popupTextField .MuiSelect-select {
    border-radius: 5px;
    border: 1px solid #2e7d32;
}

.tabPanelAction {
    display: flex;
    gap: 15px;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background-color: white;
    padding: 15px 0;
}

.actionBtn {
    flex-grow: 1;
}

.actionBtnCloseOnly {
    width: 50%;
}

.patientDetails {
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #3D7CC9;
    margin-bottom: 10px;
}

.sentMessage {
    background-color: #F3F9FF;
    padding: 15px 10px;
    color: #000000;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
}

.sentTime {
    font-size: 10px;
    line-height: 12px;
    color: #3D7CC9;
    font-weight: 500;
    margin-top: 10px;
}

header.subheader {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    color: #3476CD;
    margin-bottom: 10px;
    padding: 0 10px;
}

section.detailsForm:not(:first-child) {
    margin-top: 15px;
}

.tabPanelActionTab2 {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 15px;
    position: sticky;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background-color: white;
}

.formInfo {
    padding: 0 10px;
}

.formInfo .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
}

.formInfo .pLabel {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #7D7D7D;
}

.formInfo .pValue {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: right;
    color: #393939;
}

.divider {
    margin-bottom: 10px !important;
}

.formInfo .formInfoLabel {
    font-size: 10px;
    line-height: 15px;
    font-weight: 500;
    color: #393939;
    margin-bottom: 5px;
}

.formInfo .arrivalTimeDiv {
    display: flex;
    flex-direction: column;
}

.formInfo .arrivalTimeInput {
    height: 30px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    padding: 6px 10px;
    border: 2px solid black;
    border-radius: 4px;
    font-family: Poppins, sans-serif;
    background-color: white;
}

.formInfo .roomDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.detailsForm .formInfo .roomDetails .selectRoomType {
    height: 30px;
    border: 2px solid black;
    border-radius: 4px;
    padding: 6px 10px 6px 10px;
    background-color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    font-family: Poppins, sans-serif;
}

.detailsForm .formInfo .roomDetails .roomNumber {
    height: 30px;
    border: 2px solid black;
    border-radius: 4px;
    padding: 6px 10px 6px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    font-family: Poppins, sans-serif;
    width: 100%;
}

.detailsForm .formInfo .collectionInfo {
    height: 30px;
    border: 2px solid black;
    border-radius: 4px;
    padding: 6px 10px 6px 30px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    font-family: Poppins, sans-serif;
    width: 50%;
}

.detailsForm .formInfo .collectionInfo::-webkit-outer-spin-button,
.detailsForm .formInfo .collectionInfo::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.detailsForm .formInfo .rupeeLabel {
    position: absolute;
    left: 10px;
    bottom: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    font-family: Poppins, sans-serif;
}

.formInfo .otDates .MuiFormControl-root.MuiTextField-root {
    width: 100%;
}

.formInfo .otDates input.MuiOutlinedInput-input.MuiInputBase-input {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    padding: 6px 10px;
    border-radius: 4px;
    font-family: Poppins, sans-serif;
    background-color: white;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth.Mui-selected {
    color: white;
    background-color: #3D7CC9;
    border: 1px solid #3D7CC9;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.MuiTab-fullWidth {
    color: #3D7CC9;
    background-color: white;
}

.formInfo .Mui-error .MuiOutlinedInput-notchedOutline {
    border: unset !important;
}

.formInfo .arrivalTimeDiv input.MuiOutlinedInput-input.MuiInputBase-input {
    box-sizing: border-box;
    height: 30px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #000000;
    padding: 6px 10px;
    border-radius: 4px;
    font-family: Poppins, sans-serif;
    background-color: white;
}

.micOff {
    position: absolute;
    right: 0.5rem;
    transform: translate(0%, -60%);
    top: 50%;
}

.backdrop-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backdrop-container .image-container {
    height: 575px;
    display: flex;
    align-items: center;
    max-width: 100%;
}

.backdrop-container .image-container .info-image {
    width: 100%;
    max-height: 560px;
}

.backdrop-container .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
