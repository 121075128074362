.inputFieldLabel{
    margin: 0 0 5px !important;
    color: #393939 !important;
    font-size: 10px !important;
    font-weight: 500 !important;
}
.inputFieldError{
    margin: unset !important;
    font-size: 10px !important;
    font-weight: 500 !important;
}
.inputStartAdornment p {
    font-size:12px !important; 
    color: #393939 !important;
    line-height:18px !important;
}
.inputStartAdornment {
    margin-right:4px !important; 
}
.inputFieldStyle input{
padding: unset !important;
}
.inputEndAdornment{
    margin-right: -10px;
}