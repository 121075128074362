.centralFilters_boxContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 4px;
  border-radius: 4px;
  background: #FFFFFF33;
  height: 30px;
  white-space: nowrap;
}

.centralFilters_Container {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px 0px #7575754D;
  border-radius: 4px;
  color: #000;
  position: absolute;
  top: 85%;
  right: 10px;
  z-index: 10;
}

.centralFilters_Container .MuiAccordionDetails-root {
  padding: 0px 16px 5px;
  max-height: 150px;
  overflow-y: auto;
}
.centralFilters_Container .MuiTypography-root{
  font-size: 12px;
}
.centralFilters_Container .MuiAccordionSummary-root {
  min-height: 40px !important;
}

.centralFilters_Container .MuiAccordion-root:before {
  content: unset
}

.centralFilters_buttonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: 0.5rem 0;
}

.centralFilters_clearButton {
  text-decoration: underline;
  color: #3476CD;
}

.centralFilters_applyButton {
  background-color: #3476CD;
  border-radius: 4px;
}
.centralFilters_Container .MuiAccordionSummary-content{
  margin: 0px;
}