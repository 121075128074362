.generic-popUp {
  height: 100vh;
  overflow: auto;
}
.generic-popUp .content-container .modal-header {
  background-color: #3D7CC9;
  padding: 1rem 2rem;
  position: relative;
  border-radius: 0px 0px 15px 15px;
}
.generic-popUp .content-container .modal-header .actions-container {
  max-height: 48px;
}
.generic-popUp .content-container .modal-header .search-input-container {
  width: 100%;
  border-radius: 10px;
  color: #000;
  display: flex;
  justify-content: space-between;
  justify-self: flex-end;
  padding: 0.25em;
  font-size: 16px;
  max-width: 500px;
  margin: 0;
  height: auto;
}
.generic-popUp .content-container .modal-header .search-input-container input {
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font-size: inherit;
}
.generic-popUp .content-container .modal-header .close-modal {
  position: absolute;
  top: 0;
  right: 30;
  border-radius: 100%;
  background: #fff;
  transform: translate(50%, -50%);
}
@media (min-width: 700px) {
  .generic-popUp .content-container .modal-header .close-modal {
    right: 0;
  }
}/*# sourceMappingURL=styles.css.map */