.container-okSurgeryStatus{
  background-color: #009817;
  color: #fff;
}
.container-okSurgeryStatus-active{
  background-color: #fff;
  color: #009817;
}
.arrivedMediaCard{
  padding:0px !important;
  margin-left: 0.7rem;
  padding-bottom: 1rem;
  height: 25px !important;
}
.common-okSurgeryStatus{
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
}
.common-okSurgeryStatus img {
  height: 10px;
  width: 10px;
}
.common-okSurgeryStatus span{
  font-size: 10px;
}

.otDateTime-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.otDateTime-section{
  display: flex;
  flex-direction: column;
  text-align: left;
}
.otDateTime-section p{
  margin: 0;
  padding: 0;
}
.otDateTime-sectionHeader{
  font-size: 8px;
}
.otDateTime-sectionText{
  font-size: 10px;
  font-weight: 600;
}
.cards-newContainer{
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-direction: row;
}