.accordionContainer{
    margin: 0px 16px 10px !important;
    box-shadow: 0px 4px 10px 0px #75757533 !important;
    background: white !important;
    border-radius: 4px !important;
    width: unset !important;
}
.accordionHeaderText{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #3476CD !important;
}
.accordionBodyDetails{
    border-top: 0.5px solid #3476CD33 !important;
    padding: 10px 10px 15px!important;
}
.accordionHeaderContainer{
    min-height: 45px !important;
}
.accordionBodyDataContainer{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.accordionSaveButton {
    border: unset;
    background: white;
    color: #3476CD;
    border: 1px solid #3476CD;
    border-radius: 4px;
    line-height: 25px;
    margin-top: 10px;
    cursor: pointer;
}
.accordionSaveButtonContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.accordionPendingFiendsText{
    font-size: 10px !important;
    font-weight: 500 !important;
    color: #DB3A3A !important;
    margin-left: 5px !important;
    white-space: nowrap !important;
}
.MuiAccordionSummary-content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.accordionAddMoreButtonContainer{
    justify-content: center;
}
.addMoreButton{
    height: 30px;
    background: #3778CE;
    border: 1px solid #3778CE;
    border-radius: 4px;
    color: white;
    font-weight: 500;
    font-size: 12px;
    width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.disabledStyle{
    border: 1px solid gray;
    color: gray;
}