.mention {
  color: #4a85bb;
  text-decoration: none;
}

.mentionSuggestions {
  border-top: 1px solid #eee;
  background: #fff;
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform-origin: 50% 0%;
  margin: -16px;
  position: absolute;
  top: 15px !important;
  height: 160px;
  overflow-y: scroll;
  background: #f1f1f1;
  width: 100%;
  left: 10px !important;
  color: #00263e;
  transform-origin: unset !important;
  /* transform: none!important; */
  transition: none !important;
}

.mentionSuggestions::-webkit-scrollbar {
  width: 20px;
}

.mentionSuggestions::-webkit-scrollbar-track {

  background-color: #f1f1f1;
  border-radius: 10px;
}

.mentionSuggestions::-webkit-scrollbar-thumb {
  background-color: #00263e;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.mentionSuggestions::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.mentionSuggestionsEntryContainer {
  display: table;
  width: 100%;
}

.mentionSuggestionsEntryContainerLeft,
.mentionSuggestionsEntryContainerRight {
  display: table-cell;
  vertical-align: middle;
}

.mentionSuggestionsEntryContainerRight {
  width: 100%;
  padding-left: 8px;
}

.mentionSuggestionsEntry {
  padding: 7px 10px 10px 10px;
}

.mentionSuggestionsEntry:active {
  background-color: #f1f1f1;
  color: #00263e;

}

.mentionSuggestionsEntry:hover {
  background-color: #f8be32;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #f1f1f1;
  color: #00263e;

}

.mentionSuggestionsEntryText,
.mentionSuggestionsEntryTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.mentionSuggestionsEntryTitle {
  color: blue;
}

.mentionSuggestionsEntryText {
  overflow-x: hidden;
  text-indent: -15px;

}

.mentionSuggestionsEntryTitle {
  font-size: 80%;
  color: #a7a7a7;
  display: block;
}

.mentionSuggestionsEntryAvatar {
  display: none;

  width: 30px;
  height: 30px;
  border-radius: 50%;
}