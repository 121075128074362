.documentUploadPopover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 5px 5px 15px #00000029;
}

.popoverIcons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px 20px 20px 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.popoverTitle {
  color: #3d7cc9;
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  font-weight: 600;
}

.popoverIcons>div>img {
  width: 80px;
  height: 80px;
}

.popoverIcons>div {}

.uploadDocumentContainer {
  display: flex;
  flex-direction: column;
}

.uploadDocumentHeader {
  background-color: #3D7CC9;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  flex-direction: row-reverse;
  display: flex;

}

@media (min-width: 900px) {
  .uploadDocumentHeader {
    border-radius: 0px 0px 15px 15px;
  }
}

.uploadDocumentBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  flex-grow: 1
}

.uploadDocLeftContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* height: 72vh; */

}
.collectedAmountInput{
  border-radius: 8px;
  color: black;
  border: 1px solid gray;
  height: 37px;
  margin-top: 10px;
  padding-left: 10px;
  width: 100%;
  font-size: 14px;
}
.leftContainerPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 400px; */
  background: white;
  /* height: 480px; */
  /* height:55vh; */
  border-radius: 5px;
  box-shadow: 5px 5px 15px #0000000d;
}

.uploadDocRightContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  /* height: 72vh; */
}

.leftContainerHeading {
  color: #3D7CC9;
}

.rightContainerHeading {
  color: #3D7CC9;
}

.uploadDocumentSelect {
  /* padding: 10px; */
}

.uploadDocumentFormSelect {
  /* box-shadow: 0px 3px 6px #00000029; */
  /* font-size: 24px; */
}

.completeDocThumbnail {
  height: unset !important;
  width: unset !important;
}

.uploadDocumentActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  bottom: 0px;
  position:"absolute";
  gap:10px;
  padding-left: 10px;
  padding-right:10px
  /* padding-right:1rem */
}

.uploadActionButton {
  width: 100%;
  border-radius: 5px !important;
  padding: 0.25em 0.5em 0.25em 0.5em !important;
  height: 40px;
  font-size: calc(11px + (20 - 12) * ((100vw - 360px) / (1920 - 360))) !important;
  margin-bottom: 10px !important;
}

.uploadCompleteDocThumbnail {
  align-items: center;
  width: 100%;
  height: 130px;
  box-shadow: rgb(0 0 0 / 5%) 5px 5px 15px;
  position: relative;
  display: flex;
  justify-content: center;
  background: white;
  flex-direction: column;
}

.uploadDocThumbnail {}

.thumbnailList {
  padding: 10px;
  overflow-x: hidden;
  /* max-height: 52vh; */
  align-items: center;
  overflow-y: auto;
}

.previewDocumentsContainer::-webkit-scrollbar {
  height: 4px
}

/* Track */
.previewDocumentsContainer::-webkit-scrollbar-track {
  background: #F5F5F5 !important;
}

.uploadDocumentBody::-webkit-scrollbar {
  width: 4px
}

/* Track */
.uploadDocumentBody::-webkit-scrollbar-track {
  background: #F5F5F5 !important;
}


#outerContainer #mainContainer div.toolbar {
  display: none !important; /* hide PDF viewer toolbar */
}
#outerContainer #mainContainer #viewerContainer {
  top: 0 !important; /* move doc up into empty bar space */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .collectedAmountInput{
    height: 35px;
    font-size: 12px;
  }
}