.spinning-loader-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.85);
  display: -ms-grid;
  display: grid;
  position: fixed;
  inset: 0;
  place-content: center;
  z-index: 999999999;
  -webkit-transition: 0.3s ease opacity;
  transition: 0.3s ease opacity;
  opacity: 0;
  pointer-events: none;
  /* slightly transparent fallback */
  background-color: rgba(255, 255, 255, 0.7);
  /* if backdrop support: very transparent and blurred */
}

.spinning-loader-container .spinning-loader {
  -ms-grid-column-align: center;
      justify-self: center;
  width: 50px;
  height: 50px;
  border: 3px solid #00b6bc;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  border-left-color: #fff;
  border-radius: 50%;
  background: transparent;
  -webkit-animation-name: rotate-s-loader;
          animation-name: rotate-s-loader;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}

.spinning-loader-container .spinning-loader-message {
  -webkit-transition: 0.1s ease opacity;
  transition: 0.1s ease opacity;
  opacity: 0;
  pointer-events: none;
}

@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .spinning-loader-container {
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
  }
}

@-webkit-keyframes rotate-s-loader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate-s-loader {
  from {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spinning-loader-container.show-element,
.spinning-loader-container > *.show-element {
  opacity: 1;
  pointer-events: all;
}
