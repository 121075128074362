.search-input-container {
  /* min-width: 260px; */
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.25em;
  font-size: 14px;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0.5rem;
  max-width: 100%;
  height: 40px;
}

.search-input-container input::placeholder {
  color: #000;
}

.search-input-container input {
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font-size: inherit;
  width: 100%;
}

.patient-search-container {
  margin: 0.5rem;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr auto;
  grid-template: 1fr / 1fr auto;
  gap: 0.5rem;
}

.patient-search-container .search-input-container {
  margin: 0;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: #fff;
  border-radius: 10px;
  max-width: 100%;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
}

.patient-search-container .group-info-btn-container {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0px 3px 6px #00000029;
  box-shadow: 0px 3px 6px #00000029;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

::-webkit-scrollbar {
  /* width: 10px; */
}

::-webkit-scrollbar-track {

  background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.filterPopUp {
  overflow: hidden;
  border-radius:8px
}

.filterHeading {
  background: #3D7CC9;
  padding: 1rem;
  margin-top: -3px;
  color: white;
  font-weight: 600;
 border-radius: 8px 8px 8px 8px;
  place-content: center;
  display: flex;
  justify-content: center;
}

.filterBody {
  background: white;
  height: 13rem;
}

.filterBody>div>.multiSelectContainer>.optionListContainer {
  display: unset !important;
}
.option:hover{
  background:white!important;
  color:black!important;
}
.selected{
  background:white!important;
  color:black;

}


.filterBody>div>.multiSelectContainer ul {
  display: block;
  padding: 0;
  margin: 0;
  border: none !important;
  border-radius: 4px;
  max-height: none;
  height: 9rem;
  overflow-y: auto;

}

.filterBody>div>.multiSelectContainer>.searchWrapper {
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  min-height: 22px;
  position: relative;
  display: flex;
  /* width: 18rem; */
  overflow-x: auto;
}

.filterActions {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
gap:10px;
white-space:nowrap;
background:white;
margin-top: -1px;

}
.filterActions>button{
 font-size: calc(
    7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
  ) 
}
  
  .searchWrapper::-webkit-scrollbar-track {
    
    background: white;
  }
/* .searchWrapper::-webkit-scrollbar {
  width: 20px;
}
  

.searchWrapper::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.searchWrapper::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
} */

.alarmBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.alarmCross {
  display: flex;
  justify-content: end;
}

.closeIcon {
  color: white;
  font-weight: 600;
  cursor: pointer;

}

.alarmBody {
  margin: auto;
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 2rem;
  position: relative;
}

.alarmImage {
  margin-top: -2rem;
}

.alarmContent {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
}

.alarmContent>.alarmHeading {
  font-weight: 600;
  font-size: 23px;
}

.alarmContent>.alarmDetails {
  justify-content: space-around;
  font-weight: 500;
}

.alarmActions {
  display: flex;
  justify-content: space-around;
  margin-bottom: -20px;
}

.actionButton {
  background: 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 11px #00000027;
  border-radius: 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9rem;
}

.actionButton>Button {
  color: white;
  padding: 0.5rem;
}

.logoImage {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}

.chip>img{
  margin-left:10px;
  cursor:pointer
}