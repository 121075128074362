.vertical-bar-chart {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  height: 100%; /* Adjust the height as needed */
}

.bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bar {
  width: 40px; /* Adjust the width as needed */
  background-color: #f7bf45;
  text-align: center;
  color: white;
  margin-bottom: 5px;
  position: relative;
}

.value {
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  color: #00263E;
  font-size: 16px;
 
}

.label {
  margin-top: 5px;
  color: #666A70;
  font-size: 12px;
}
