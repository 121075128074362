.clcContainer {
}
.clcHeader {
  position: relative;
  padding: 16px;
  background-color: #ff8300;
  color: white;
  font-size: 20px;
  font-weight: 500;
}
.clcBody {
  padding: 10px;
}
.clcSearch {
  padding: 10px;
  color: #3d7cc9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clcList {
  height: 30vh;
  display: flex;
  overflow: auto;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.pocListItem {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.pocListItem:hover {
  background: whitesmoke;
}
.pocIcon {
  flex: 1;
  cursor: pointer;
}
.pocDetails {
  display: flex;
  flex-direction: column;
  flex: 4;
}
.pocName {
  color: #3d7cc9;
  font-size: 20px;
  font-weight: 600;
}
.pocNumber {
  font-size: 18px;
  color: #3d7cc9;
}
.pocRole {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3d7cc9;
  font-size: 20px;
  font-weight: 600;
}

.clcLine {
  height: 0.5px;
  width: 80%;
  background: #00000033;
  margin: auto;
}
