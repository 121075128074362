.complaintsCardMainContainer{
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.complaintsCardIssueText{
    font-size: 12px;
    margin-left: 8px;
    color: #393939;
    font-weight: 500;
}
.complaintsCardContainer{
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    padding: 10px;
    background-color: white;
}
.complaintsCardHeaderContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.5px solid #3476CD33;
}
.complaintsCardHeaderHeading{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #3476CD;
    text-transform: capitalize;
}
.complaintsCardBodyContainer{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.complaintsCardIssueName{
    font-size: 10px;
    color: #3476CD;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
}
.complaintsCardChipsContainer{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin: 10px 0 15px;
}
.complaintsCardChips{
    font-size: 10px;
    color: #393939;
    font-weight: 500;
    line-height: 15px;
    background-color: #EDEDED;
    border-radius: 40px;
    padding: 5px 10px;
}
.complaintsCardRemarksContainer{
    display: flex;
    flex-direction: column;
}
.complaintsCardRemarksHeading{
    font-size: 10px;
    color: #393939;
    font-weight: 500;
    line-height: 15px;
}
.complaintsCardRemarksDescription{
    font-size: 10px;
    color: #393939;
    font-weight: 400;
    line-height: 16px;
}
.complaintsCardFooterContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.complaintsCardTimeContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.complaintsCardUserContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.complaintsCardTimeText{
    font-size: 8px;
    color: #3476CD;
    font-weight: 500;
    line-height: 12px;
    margin-right: 4px;
}
.complaintsCardTimerValue{
    font-size: 8px;
    color: #393939;
    font-weight: 400;
    line-height: 16px;
}
.complaintsCardUserText{
    font-size: 8px;
    color: #3476CD;
    font-weight: 500;
    line-height: 12px;
    margin-right: 4px;
}
.complaintsCardUserName{
    font-size: 8px;
    color: #393939;
    font-weight: 400;
    line-height: 16px;
}
.complaintsCardCheckBox{
    display: flex;
    flex-direction: row;
    align-items: center;
}
@media (max-width:768px){
    .complaintsCardMainContainer{
        grid-template-columns: 1fr;
    }
}