.centralHospitalList_header{
  display: flex;
  flex-direction: row;
  padding: 10px 0px 10px 30px;
  align-items: center;
  justify-content: center;
  gap:10px;
  box-shadow: 0px 1px 4px 0px #75757533;
}
.centralHospitalList_headerFields{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-evenly;
}
.centralHospitalList_header p{
   margin: 0px;
   padding: 0px;
   font-size: 14px;
   color: #3476CD;
   font-weight: 500;
}
.centralHospitalList_header span{
  font-size: 10px;
  color: #3476CD;
  font-weight: 500;
}
.centralHospitalList_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
}