.sliderItem{
    height:76px!important;
    width: 110px!important;
    margin-left:10px;
    background-color:white;
    border-radius: 5px;

  }

  .slick-list{
    padding-top: 10px;
  }