.btns-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  gap: 0.5rem 1.5rem;
  margin: 1rem auto;
  margin-top: 0;
  max-width: 25rem;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.btns-group .btn-item {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  min-width: 10rem;
}
