

.documents-body-container .modal-header {
  background-color: #3D7CC9;
  padding: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}

.documents-body-container .modal-header .search-input-container {
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.25em;
  font-size: 16px;
}

.documents-body-container .modal-header .search-input-container input {
  border: none;
  outline: none;
  background: none;
  color: inherit;
  font-size: inherit;
}

.documents-body-container .modal-header .close-modal {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 100%;
  background: #fff;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
