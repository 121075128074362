 .rdrMonth {
    width: unset
}

.patientFilterDatePicker {
    width: 100%;
    margin-top: -10px;
}


 .rdrCalendarWrapper {
    width: 100%;
    font-size: 10px;
    border:1px solid #3d7cc9;
    margin-top: 10px;
    border-radius: 5px;
}
.rdrDateRangePickerWrapper{
    width:100%
}
 .rdrMonthAndYearWrapper{
    height:fit-content;
    margin-top:0px;
    padding-top: 0px;
}

 .rdrMonth{
    padding:unset;
    font-size: 10px;
    border:1px solid #3d7cc9;
    border-radius: 5px;
    border-left:0px;
    border-right: 0px;
    border-bottom:0px;


}
.rdrMonthPicker {
    font-size: 10px;
}
.rdrMonthPicker select {
border-right:1px solid #3d7cc9;
}
.rdrYearPicker select{
    border-left:1px solid #3d7cc9;

}
.patientFilterDatePicker .rdrDay{
height:2.5em
}
 .rdrDayToday .rdrDayNumber span:after {
bottom:-1px
}
.rdrMonthAndYearPickers{
    border:1px solid #3d7cc9;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom:0px;
    border-radius: 5px;
    background:#F5F5F5
}
