.patient-list-container {
  display: grid;
  grid-template: auto auto auto/1fr;
  overflow: auto;
  justify-content: stretch;
  /* margin: 0 0.5rem; */
}
.patient-list-container .patient-list-item {
  overflow: auto;
  overflow-x: hidden;
  transition: 0.3s ease;
  max-height: 50vh;
}
.patient-list-container .patient-list-item details {
  transition: 0.3s ease;
}
.patient-list-container .patient-list-item details summary {
  background-color: #ddd;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  transition: 0.3s ease;
  list-style: none;
  cursor: pointer;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}
.patient-list-container .patient-list-item details summary::marker, .patient-list-container .patient-list-item details summary::-webkit-details-marker {
  display: none;
}
.patient-list-container .patient-list-item details summary .title {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.patient-list-container .patient-list-item details summary .title .icon {
  display: inline-grid;
  place-content: center;
  transition: 0.3s ease transform;
}
.patient-list-container .patient-list-item details, .patient-list-container .patient-list-item details summary {
  border: none;
  outline: none;
}
.patient-list-container .patient-list-item details:hover, .patient-list-container .patient-list-item details:focus, .patient-list-container .patient-list-item details summary:hover, .patient-list-container .patient-list-item details summary:focus {
  border: none;
  outline: none;
}
.patient-list-container .patient-list-item details[open] summary {
  background: #f1f1f1;
}
.patient-list-container .patient-list-item details[open] summary .title .icon {
  transform: rotate(90deg);
}
.patient-list-container .patient-list-item .title {
  color: #3D7CC9;
  font-size: clamp(12px, 5vw, 16px);
  margin: 0;
  font-weight: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.drawer-icon{
  display: none;
}

 @media screen and (min-width:900px) {
.drawercollapse{
  visibility: hidden;
  transition: visibility 0s ease-in 0.3s;
}

.drawerexpand{
  visibility: visible;
}

.drawer-title{
  margin: 0;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.drawer-icon{
  cursor: pointer;
  width: 24px;
  height: 20px;
  display: block;
}

.drawer-active{
  flex-basis: 0% !important;
  transition: all 0.3s ease-in;
}

.drawer-nonactive{
  flex-basis: 25% !important;
  transition: all 0.3s ease-in;
}
.showCallIcon{
  visibility: visible;
  transition: visibility 0s ease-in 0.3s;
}

.hideCallIcon{
  visibility: hidden;
}

.drawer-open{
  right: 50%;
}

.drawer-closed{
  right: 72%;
}
}
/*# sourceMappingURL=styles.css.map */