.tabContainer{
  padding-top: 2rem;
  padding-left: 2rem;
}
.tabContainer .MuiTabs-flexContainer{
  gap: 1rem;
}
.tabContainer .MuiTabs-indicator{
  background-color: white;
}
.tabContainer .Mui-selected{
   border: 1px solid white;
   color:#00263E;
}
.tabContainer button{
  color : #3D7CC9;
  border:1px solid #3D7CC9;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .tabContainer{
    padding:0px;
  }
  .tabContainer .MuiTabs-flexContainer{
    gap: 0rem;
  }
  .tabContainer button{
    font-size: 14px;
    flex:0.4
  }
}
