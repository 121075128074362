.wrapper {
  display: flex;
  padding: 10px 0;
  gap: 20px;
}
.wrapper:first-child {
  padding-top: 0;
}
.left {
  flex: 100%;
}
.right {
  text-align: end;
  flex: 10%;
}
.itemName {
  color: #07263e;
  font-size: 14px;
}

.itemValue {
  color: #3d7cc9;
  font-size: 14px;
}

@media only screen and (max-width: 600px) {
  .itemName {
    font-size: 12px;
  }
  .itemValue{
    font-size: 12px;
  }
}
