.filtersContainer {
  display: flex;
  padding: 10px 10px 10px 0px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.filter {
  text-transform: none !important;
  box-shadow: 5px 5px 15px #0000000d !important;
  border-radius: 5px !important;
  padding: 0.5em 1em 0.5em 1em !important;
  margin-bottom: 10px !important;
  white-space: nowrap;
  justify-content: flex-start!important;
}
.rdrDefinedRangesWrapper {
  display: none !important;
}
.backArrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 11px;
  color: grey;
}

.filterSearch {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 25px 20px 0px 0px;
}

.filterActionButton {
  margin-left: 10px !important;
  box-shadow: 5px 5px 15px #0000000d !important;
  border-radius: 5px !important;
  padding: 0.5em 1em 0.5em 1em !important;
  margin-bottom: 10px !important;
  text-transform: none !important;
  font-size: calc(
    7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
  ) !important;
  height:50px;
  width:9.1vw
}

.inputBase> div>.MuiInputBase-input::placeholder{
  color:"#3D7CC9";
  padding-left:10px;
  opacity: unset!important;
  font-size: calc(
    7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
  ) !important;}

  .inputBase> div>.MuiInputBase-input{
    font-size: calc(
      7px + (20 - 12) * ((100vw - 360px) / (1920 - 360))
    ) !important;
  }
.checklistFilter{
  height:50px;
  width:18.2vw;
}
.checklistFilter>Button{
  height:100%;
  width:100%;
  font-size: calc( 7px + (20 - 12) * ((100vw - 360px) / (1920 - 360)))!important;

}
.optionContainer{
  font-size: 14px;
 border-radius: 0px!important;
}
.optionListContainer {
  border-radius: 0px!important;

}

.optionContainer>.option{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.optionContainer>.selected{
  background:none!important;
  color:black!important
}

